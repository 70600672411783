<script>
import store from "@/state/store.js";
import AdminTaskDashboard from "@/views/pages/tasks/components/AdminTaskDashboard.vue";
import ManagerTaskDashboard from "@/views/pages/tasks/components/ManagerTaskDashboard.vue";

export default {
  name: "Index",
  components: {
    AdminTaskDashboard,
    ManagerTaskDashboard
  },
  data() {
    return {
      title: "My Tasks",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "My Tasks",
          active: true,
        },
      ],
      date: new Date().getTime()
    };
  },
  computed: {
    user() {
      return store.state.user;
    },
    sayHelloToUser() {
      return 'Hello ' + store.state.user.full_name || 'Unknown User';
    },
  }
}
</script>

<template>
  <template v-if="user.role === 'Admin'">
    <AdminTaskDashboard/>
  </template>
  <template v-else>
    <ManagerTaskDashboard/>
  </template>
</template>

<style scoped>

</style>