<script>
export default {
  name: "AdminTaskDashboard"
}
</script>

<template>
Admin Task Dashboard
</template>

<style scoped>

</style>